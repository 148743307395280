<template>
    <div id="Services" class="min-h-screen bg-black flex justify-center items-center p-8">
      <div class="max-w-7xl w-full text-center">
        <h2 class="text-4xl font-semibold text-gray-300 mb-10">Our Services</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div class="service-card bg-gradient-to-br from-gray-700 to-gray-900 p-6 rounded-lg shadow-xl border border-gray-600">
            <h3 class="text-2xl text-white font-semibold mb-4">Local Delivery</h3>
            <p class="text-gray-300 leading-relaxed">
              Dedicated local delivery services ensuring timely and safe transportation of your goods within the city limits.
            </p>
          </div>
          <div class="service-card bg-gradient-to-br from-gray-700 to-gray-900 p-6 rounded-lg shadow-xl border border-gray-600">
            <h3 class="text-2xl text-white font-semibold mb-4">Long Haul Transport</h3>
            <p class="text-gray-300 leading-relaxed">
              Specializing in long-distance freight services across states with a focus on safety and punctuality.
            </p>
          </div>
          <div class="service-card bg-gradient-to-br from-gray-700 to-gray-900 p-6 rounded-lg shadow-xl border border-gray-600">
            <h3 class="text-2xl text-white font-semibold mb-4">Specialized Freight</h3>
            <p class="text-gray-300 leading-relaxed">
              Handling delicate and specialized cargo with tailored solutions for unique transportation needs.
            </p>
          </div>
          <!-- Add more service cards as needed -->
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'Services',
    setup() {
      // Component setup
      return {};
    }
  });
  </script>
  
  <style scoped>
  #Services {
    background-color: #121212; /* Black background for the services section */
  }
  
  .service-card {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: 1px solid #525252; /* Chrome-like border for the cards */
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.25); /* Chrome-like shine effect */
  }
  
  @media (max-width: 768px) {
    #Services {
      padding: 4rem 2rem;
    }
  
    .service-card {
      margin-bottom: 2rem;
    }
  }
  </style>
  