<template>
  <div class="bg-white min-h-screen" id="Header">
    <header class="absolute inset-x-0 top-0 z-50 bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg">
      <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#Header" class="p-1.5">
            <img class="h-8 w-auto" src="../assets/b_c1-01.svg" alt="Black & Chrome Logo" />
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="mobile-menu-button text-white" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <a v-for="item in navigation" :key="item.name" :href="item.href" class="navigation-link leading-6 text-white">{{ item.name }}</a>
        </div>
      </nav>
      <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-40 bg-black bg-opacity-50"></div>
        <DialogPanel class="fixed top-16 left-0 right-0 z-50 bg-transparent shadow-lg overflow-hidden">
          <div class="flex justify-between items-center p-4">
            <button type="button" class="text-gray-100" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="flex justify-center space-x-4 py-2">
            <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-base font-semibold text-gray-100 hover:text-gray-200">
              {{ item.name }}
            </a>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
    <div class="flex-grow mt-20 w-full flex justify-center items-center px-6 py-32 sm:py-40 lg:px-8">
      <div class="flex flex-col justify-center items-center lg:flex-row lg:gap-x-16 xl:gap-x-8 w-full max-w-7xl">
        <div class="text-center lg:text-left max-w-xl lg:max-w-1/2">
          <h1 class="text-5xl font-bold tracking-tight text-gray-300 sm:text-6xl">We’re changing the way freight is delivered.</h1>
          <p class="mt-6 text-lg leading-8 text-gray-400">
            With decades of experience in the trucking industry, we have been delivering goods reliably and efficiently. Our commitment to excellence and customer satisfaction has made us a trusted partner in transportation. We understand the importance of timely deliveries and the value of your cargo. When you choose us, you choose a legacy of dependable service that drives your business forward.
          </p>
          <a href="#Contact" class="button-animated mt-8 inline-block bg-gray-700 text-white font-semibold py-2 px-4 rounded hover:bg-gray-600 transition duration-300">Contact Us</a>
        </div>
        <div class="mt-10 lg:mt-0 lg:flex lg:justify-center lg:items-center w-full lg:w-1/2 flex justify-center">
          <img src="../assets/header-image.png" alt="Header Image" class="h-auto max-w-xl mx-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'Header',
  components: {
    Dialog,
    DialogPanel,
    Bars3Icon,
    XMarkIcon
  },
  setup() {
    const mobileMenuOpen = ref(false);

    const navigation = [
      { name: 'Home', href: '#Header' },
      { name: 'About Us', href: '#About' },
      { name: 'Services', href: '#Services' },
      { name: 'Contact Us', href: '#Contact' },
    ];

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
      console.log("Mobile menu state: ", mobileMenuOpen.value);
    };

    const smoothScroll = (href: string) => {
        const targetElement = document.querySelector(href) as HTMLElement | null;
        if (targetElement) {
          const offset = document.querySelector('header')?.offsetHeight || 0;
          window.scrollTo({
            top: targetElement.offsetTop - offset,
            behavior: 'smooth',
          });
        }
      };
    return {
      mobileMenuOpen,
      navigation,
      smoothScroll,
      toggleMobileMenu,
    };
  },
});
</script>

<style scoped>
#Header {
  background: linear-gradient(to bottom right, #0f0c29, #302b63);
}

@media (max-width: 768px) {
  #Header {
    background: linear-gradient(to bottom, #3a1c71, #d76d77);
  }
}

.button-animated {
  background-color: #e0e0e0; /* Chrome-like button color */
  color: #121212; /* Dark text for contrast */
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  overflow: hidden; /* Ensure that pseudo-elements don't overflow the button bounds */
  position: relative; /* Position relative to allow absolute positioning inside */
  will-change: transform; /* Hint to browsers for performance optimization of the transform */
}

.button-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  transition: all 650ms;
}

.button-animated:hover::before {
  left: 100%;
}

.button-animated:hover {
  background-color: #cacaca; /* Lighter shade for hover effect */
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-color: #cacaca; /* Chrome-like border color on hover */
}
</style>
