<template>
    <div id="Contact" class="min-h-screen bg-gradient-to-br from-black via-gray-800 to-gray-900 flex justify-center items-center p-8">
      <div class="max-w-7xl w-full text-center">
        <h2 class="text-4xl font-semibold text-gray-300 mb-10">Contact Us</h2>
        <div class="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-xl inline-block w-full max-w-md">
          <form @submit.prevent="submitContactForm" class="flex flex-col gap-4">
            <div class="flex flex-col">
              <label for="name" class="text-gray-300 mb-2">Your Name</label>
              <input type="text" id="name" v-model="contactForm.name" class="p-2 rounded bg-gray-700 text-white" required>
            </div>
            <div class="flex flex-col">
              <label for="email" class="text-gray-300 mb-2">Your Email</label>
              <input type="email" id="email" v-model="contactForm.email" class="p-2 rounded bg-gray-700 text-white" required>
            </div>
            <div class="flex flex-col">
              <label for="message" class="text-gray-300 mb-2">Your Message</label>
              <textarea id="message" v-model="contactForm.message" rows="4" class="p-2 rounded bg-gray-700 text-white" required></textarea>
            </div>
            <button type="submit" class="button-animated mt-4 bg-gray-700 text-white font-semibold py-2 px-4 rounded hover:bg-gray-600 transition duration-300">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'ContactUs',
    setup() {
      const contactForm = ref({
        name: '',
        email: '',
        message: ''
      });
  
      const submitContactForm = () => {
        // Handle the form submission logic
        // You might want to send the form data to a server or email service
        console.log(contactForm.value);
        alert('Form submitted!'); // Placeholder for actual submission logic
      };
  
      return {
        contactForm,
        submitContactForm
      };
    },
  });
  </script>
  
  <style scoped>
  #ContactUs {
    /* Gradient background */
    background: linear-gradient(to bottom right, #121212, #525252);
  }
  
  .button-animated {
    background-color: #e0e0e0; /* Chrome-like button color */
    color: #121212; /* Dark text for contrast */
    /* Other button styles */
  }
  
  .button-animated:hover {
    background-color: #cacaca; /* Lighter shade for hover effect */
    /* Hover transformations */
  }
  
  /* Additional styles for form elements */
  input, textarea {
    border: 1px solid #333;
    transition: border-color 0.3s;
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: #e0e0e0; /* Chrome-like focus color */
  }
  </style>
  