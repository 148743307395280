<template>
    <div id="About" class="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-blue-900 flex justify-center items-center px-8 py-16">
      <div class="max-w-7xl w-full flex flex-col lg:flex-row justify-center items-center gap-8">
        <div class="flex-1 flex justify-center items-center">
          <img src="../assets/kamion-slika-abou.png" alt="Truck Image" class="h-auto max-w-xs lg:max-w-lg xl:max-w-2xl shadow-lg" />
        </div>
        <div class="flex-1 flex flex-col justify-center items-start space-y-4">
          <h2 class="text-4xl font-semibold text-white">About Our Trucking Company</h2>
          <p class="text-lg text-gray-300 leading-relaxed">
            We take pride in being a long-standing and trusted name in the trucking industry. Established several decades ago, our journey began with a simple goal: to provide top-notch transportation services that meet and exceed our clients' expectations.
          </p>
          <p class="text-lg text-gray-300 leading-relaxed">
            Over the years, we have grown and evolved, adapting to the changing landscape of logistics and technology. However, our core values remain unchanged - reliability, efficiency, and customer satisfaction.
          </p>
          <p class="text-lg text-gray-300 leading-relaxed">
            As a company deeply rooted in the transportation business, we understand the importance of on-time deliveries, safety, and the impact our services have on your supply chain. Our team of dedicated professionals, advanced technology, and a fleet of well-maintained trucks ensure that your cargo is in safe hands.
          </p>
          <p class="text-lg text-gray-300 leading-relaxed">
            Whether you are a small business looking for a dependable shipping partner or a large corporation seeking efficient logistics solutions, we are here to serve you. Our commitment to excellence drives us to deliver your goods safely and on schedule, every time.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'About',
    setup() {
      // Component setup
      return {};
    }
  });
  </script>
  
  <style scoped>
  #About {
    background: linear-gradient(to bottom right, #0f0c29, #302b63, #24243e);
  }
  
  #About img {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transition: max-width 0.3s ease-in-out;
  }
  
  @media (max-width: 1024px) {
    #About img {
      max-width: 80%;
    }
  }
  
  @media (max-width: 768px) {
    #About {
      padding: 4rem 1rem;
    }
  
    #About .max-w-7xl {
      flex-direction: column;
    }
  
    #About img {
      max-width: 100%;
      margin-bottom: 2rem;
    }
  }
  </style>