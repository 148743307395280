import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import MainPage from '../views/MainPage.vue';
import Header from '../components/Header.vue';
import AboutUs from '../components/AboutUs.vue';
import Services from '../components/Services.vue';
import Contact from '../components/ContactUs.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: MainPage,
  },
  {
    path: '/Header',
    name: 'Header',
    component: Header,
  },
  {
    path: '/#About',
    name: 'About',
    component: AboutUs,
  },
  {
    path: '/#Services',
    name: 'Services',
    component: Services,
  },
  {
    path: '/#Contact',
    name: 'Contact',
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
