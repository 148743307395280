import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Header" }
const _hoisted_2 = { class: "About" }
const _hoisted_3 = { class: "Services" }
const _hoisted_4 = { class: "Contact" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_About = _resolveComponent("About")!
  const _component_Services = _resolveComponent("Services")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_About)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Services)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Contact)
    ])
  ]))
}