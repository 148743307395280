<template>
    <div>
        <div class="Header">
            <Header/>  
        </div>
        <div class="About">
            <About/>
        </div>
        <div class="Services">
            <Services/>
        </div>
        <div class="Contact">
            <Contact/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '../components/Header.vue'
import About from '../components/AboutUs.vue'
import Services from '../components/Services.vue'
import Contact from '../components/ContactUs.vue'

export default defineComponent({
    name:'Main',
    components: {
        Header,
        About,
        Services,
        Contact,
    },
    setup () {
        

        return {}
    }
})
</script>

<style scoped>

</style>